<template>
  <div>
    <v-card
      :loading="carregandoBuscar"
      elevation="1"
    >
      <v-card-title class="grey lighten-3 display-1">
        # {{ modeloVeiculo.id || '--' }}
      </v-card-title>
      <v-divider />
      <validation-observer ref="observer">
        <v-form class="mt-4">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  vid="modelo"
                  name="Modelo"
                >
                  <v-col
                    cols="1"
                    md="9"
                  >
                  <v-text-field
                    v-model="modeloNovo.descricao"
                    outlined
                    dense
                    hide-details="auto"
                    :disabled="carregandoBuscar"
                    label="Modelo"
                    :error-messages="errors"
                  />
                 </v-col>
                 <v-col
                    cols="1"
                    md="9"
                  >
                  <v-autocomplete
                    :value="valueAutocomplete"
                    v-model="modeloNovo.veiculo_marca"
                    dense
                    outlined
                    label="Marca"
                    :items="marcas"
                    item-text="descricao"
                    item-value="id"  
                    autocomplete="nope"
                    hide-details="auto" 
                  />
                </v-col>
                </validation-provider>
              </v-col>
            </v-row> 
          </v-container>
        </v-form>
      </validation-observer>
      <v-card-actions>
        <v-row>
          <v-col
            cols="12"
            class="text-left"
          >
            <btn-salvar
              :carregando="carregandoSalvar"
              @click="salvar"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  import _ from 'lodash'
  import marcas from '@/api/marcas'

  export default {
    props: {
      validacaoFormulario: {
        type: Object,
        default: () => { return {} },
      },
      carregandoSalvar: {
        type: Boolean,
        default: false,
      },
      carregandoBuscar: {
        type: Boolean,
        default: false,
      }, 
      modeloVeiculo: {
      type: Object,
      default: () => { return {} },
    }, 
    },

    data () {
      return {
        modeloNovo: {
          descricao: null, 
          marca:{
            marca_id:null,
            marca_descricao:""
          }
        },
        carregando: false, 
        marcas:[],
        valueAutocomplete: 0
      }
    },

    mounted () {
      this.carregarMarcaVeiculo()
    },

    watch: {
      validacaoFormulario (val) {
        if (!val) return

        return this.$refs.observer.setErrors(val)
      },

      modeloVeiculo: {
        immediate: true,
        handler (val) {
          if (_.isEmpty(val)) return false

          this.modeloNovo = val
        },
      },
    },

    methods: {
      salvar () {
        this.$emit('salvar', this.modeloNovo)
      },
      async carregarMarcaVeiculo () {
         
        try {
          this.carregando = true

          const resposta = await marcas.listar({
            pagina: 1,
            por_pagina: 1000,
          })
          this.marcas = resposta.data.data 
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregando = false
        }
      },
    },

  }
</script>
